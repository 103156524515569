/**
 * Авторизованный пользователь
 * @type {Object}
 */
const auth = JSON.parse(localStorage.getItem('users.auth_user', '{"lang":"en"}')) || {lang:'en'};

/**
 * Перевод надписей и сообщений
 * @type {Object}
 */
const getTranslate = require(`./../../langs/bpm_actions/${auth.lang}.json`) || {};

/**
 * Настройки визуализации действий
 *
 * @param  {String} title       Наименование
 * @param  {String} icon        Иконка https://fontawesome.com/v5/search?m=free
 * @param  {String} iconType    Тип иконки. По умолчанию fa
 * @param  {String} iconColor   Цвет иконки (CSS)
 * @param  {String} iconStyle   Стиль иконки (CSS)
 * @param  {String} color       Цвет действия/кнопки (CSS)
 * @param  {String} style       Стиль действия (CSS)
 * @param  {Boolean} hidden     Скрыть кнопку действия на панели инструментов
 * @param  {Boolean} hideIcon   Скрыть иконку у блока действия
 * @param  {Boolean} hideTitle  Скрыть наименование у блока действия
 * @param  {Number} width       Ширина по умолчанию
 * @param  {Number} height      Высота по умолчанию
 */
export default {
  queue: {
    title: getTranslate.out_queue,
    icon: 'phone',
    color: 'rgba(255, 193, 7, 0.3)',
  },
  in: {
    title: getTranslate.in_queue,
    icon: 'headset',
    color: 'rgba(255, 193, 7, 0.6)',
  },
  callbot: {
    title: getTranslate.call_bot,
    icon: 'robot',
    color: 'rgba(32, 168, 216, 0.3)',
  },
  bot_incall: {
    title: getTranslate.incoming_bot,
    icon: 'android',
    iconType: 'fab',
    style:'background: linear-gradient(-45deg, rgba(32, 168, 216, 0.5), rgba(32, 114, 214, 0.5), rgba(32, 144, 150, 0.5), rgba(35, 65, 255, 0.5)); background-size: 400% 400%; ',
    color: 'rgba(32, 168, 216, 0.6)',
  },
  chatbot: {
    title: getTranslate.chat_bot,
    icon: 'whatsapp',
    iconType: 'fab',
    color: 'rgba(77, 189, 116, 0.3)',
  },
  in_chatbot: {
    title: getTranslate.chat_bot_incoming,
    icon: 'whatsapp-square',
    iconType: 'fab',
    style:'background: rgba(12, 160, 5, 0.5);background-size: 100% 100%;',
    color: 'rgba(12, 160, 5, 0.4)',
  },
  manual: {
    title: getTranslate.manual,
    icon: 'hand-paper',
    color: 'rgba(145, 65, 172, 0.3)',
  },
  custom_request: {
    title: getTranslate.custom_request,
    icon: 'plug',
    color: 'rgba(75, 83, 32, 0.4)',
  },
  transfer: {
    title: getTranslate.transfer_to_bp,
    icon: 'sign-out-alt',
    color: 'rgba(248, 108, 107, 0.3)',
  },
  split: {
    title: getTranslate.split,
    icon: 'code-branch',
    iconColor: 'rgba(248, 108, 107, 0.7)',
    color: 'rgba(32, 168, 216, 0.3)',
    style: 'background-image: linear-gradient(to bottom left, rgba(32, 168, 216, 0.3) 50%, rgba(248, 108, 107, 0.3) 50%); background-image: -webkit-linear-gradient(45deg, rgba(32, 168, 216, 0.3) 50%, rgba(248, 108, 107, 0.3) 50%);',
  },
  sms_template: {
    title: getTranslate.sms,
    icon: 'sms',
    color: 'rgba(255, 119, 0, 0.3)',
  },
  amocrm_lead: {
    title: getTranslate.integration_amocrm,
    icon: 'font',
    iconColor: 'rgba(98, 180, 219, 1)',
    color: 'rgba(206, 237, 249, 1)',
  },
  bitrix_lead: {
    title: getTranslate.integration_bitrix,
    icon: 'bold',
    iconColor: 'rgba(98, 180, 219, 1)',
    color: 'rgba(206, 237, 249, 1)',
  },
  segment: {
    title: getTranslate.segment,
    icon: 'database',
    color: '#fff',
    style: '',
    width: 80,
    height: 120,
    hideIcon: true,
  },
  segment_trash: {
    title: getTranslate.trash,
    icon: 'trash',
    color: '#fff',
    style: '',
    width: 80,
    height: 120,
    hideIcon: true,
  },
  gateway: {
    title: getTranslate.gate,
    icon: 'square',
    color: '#fff',
    iconType: 'far',
    iconStyle: 'transform: rotate(45deg);',
    style: '',
    width: 40,
    height: 40,
    hideIcon: true,
    hideTitle: true,
  },
  event_signal: {
    title: getTranslate.event_signal,
    icon: 'arrow-alt-circle-up',
    color: '#fff',
    iconType: 'fas',
    style: '',
    width: 40,
    height: 40,
    hideIcon: true,
    hideTitle: true,
  },
  event_finish: {
    title: getTranslate.event_finish,
    icon: 'arrow-alt-circle-down',
    color: '#fff',
    iconType: 'fas',
    style: '',
    width: 40,
    height: 40,
    hideIcon: true,
    hideTitle: true,
  },
  start: {
    title: getTranslate.start,
    icon: 'database',
    color: 'rgba(77, 189, 116, 0.5)',
    style: '',
    hidden: true,
    width: 40,
    height: 40,
  },
};
