import api from '@/lib/api';
import http from '@/lib/http';
import Bot from '@/lib/robot';
import VueRouter from '@/routes';

const blocks = () => {
  const auth = JSON.parse(localStorage.getItem('users.auth_user', '{"lang":"en"}')) || {lang:'en'};
  const getTranslate = require(`./../../langs/robot/${auth.lang}.json`) || {};

  return {
    bot: {
      icon: "fa fa-robot",
      title: getTranslate.bot_replicas,
      id: "bot",
      items: {
        audio: {
          icon: "fa fa-play-circle",
          title: getTranslate.br_audio,
          block: "bot",
          type: "audio",
        },
        text: {
          icon: "fa fa-font",
          title: getTranslate.br_text,
          block: "bot",
          type: "text",
        },
        wa_tpl: {
          icon: "fab fa-whatsapp",
          title: getTranslate.br_wa_tpl,
          block: "bot",
          type: "wa_tpl",
        },
        img: {
          icon: "fas fa-image",
          title: getTranslate.br_img,
          block: "bot",
          type: "img",
        },
        video: {
          icon: "fas fa-video",
          title: getTranslate.br_video,
          block: "bot",
          type: "video",
        },
        tts: {
          icon: "fab fa-soundcloud",
          title: "TTS",
          block: "bot",
          type: "tts",
        },
        // record: {
        //   icon: "fa fa-microphone",
        //   title: "Запись с микрофона",
        //   block: "bot",
        //   type: "record",
        // },fas fa-waveform-path
        hss: {
          icon: "fab fa-skyatlas",
          title: getTranslate.hss,
          block: "bot",
          type: "hss",
        },
        dtxt: {
          icon: "fa fa-cog",
          title: getTranslate.dtxt,
          block: "bot",
          type: "dtxt",
        },
        condition_audio: {
          icon: "fa fa-file-audio",
          title: getTranslate.condition_audio,
          block: "bot",
          type: "condition_audio",
        },
      },
    },
    condition: {
      icon: "fa fa-code-branch",
      title: getTranslate.conditions,
      id: "condition",
      items: {
        order_field: {
          icon: "fa fa-tasks",
          title: getTranslate.condition_order_field,
          block: "condition",
          type: "order_field",
        },
        repeat: {
          icon: "fa fa-redo",
          title: getTranslate.condition_repeat,
          block: "condition",
          type: "repeat",
        },
        transition_intents: {
          icon: "fas fa-user-clock",
          title: "Transition by intents",
          block: "condition",
          type: "transition_intents",
        },
        timeout: {
          icon: "fas fa-stopwatch",
          title: "Timeout",
          block: "condition",
          type: "timeout",
        },
      },
    },
    user: {
      icon: "fa fa-user",
      title: getTranslate.user_replicas,
      id: "user",
      items: {
        // agreement: {
        //   icon: "fa fa-plus",
        //   title: "Согласие",
        //   block: "user",
        //   type: "agreement",
        // },
        // negation: {
        //   icon: "fa fa-minus",
        //   title: "Отказ",
        //   block: "user",
        //   type: "negation",
        // },
        file: {
          icon: "fa fa-file",
          title: getTranslate.ur_file,
          block: "user",
          type: "file",
        },
        promo_code: {
          icon: "fas fa-parking",
          title: getTranslate.ur_promo_code,
          block: "user",
          type: "promo_code",
        },
        any: {
          icon: "fa fa-question",
          title: getTranslate.ur_any,
          block: "user",
          type: "any",
          noparam: true,
        },
        custom: {
          icon: "fa fa-crosshairs",
          title: getTranslate.ur_custom,
          block: "user",
          type: "custom",
        },
      },
    },
    action: {
      icon: "fa fa-bolt",
      title: getTranslate.actions,
      id: "action",
      items: {
        http_request: {
          icon: "fa fa-globe",
          title: getTranslate.act_http_request, // "Http-request",
          block: "action",
          type: "http_request",
        },
        // timeout: {
        //   icon: "fa fa-hourglass",
        //   title: "Переход по таймауту",
        //   block: "action",
        //   type: "timeout",
        // },
        done: {
          icon: "fa fa-check",
          title: getTranslate.act_done, // "Completing script",
          block: "action",
          type: "done",
          noparam: true,
        },
        call_transfer: {
          icon: "fa fa-phone-alt",
          title: getTranslate.act_call_transfer, // "Call transfer",
          block: "action",
          type: "call_transfer",
        },
        sending_sms: {
          icon: "fas fa-sms",
          title: getTranslate.act_sms, // "Sending sms",
          block: "action",
          type: "sending_sms",
        },
        stay: {
          icon: "fa fa-backward",
          title: getTranslate.act_stay, // "Stay on previous screen",
          block: "action",
          type: "stay",
        },
        fill_order: {
          icon: "fas fa-edit",
          title: getTranslate.act_fill_order,
          block: "action",
          type: "fill_order",
        },
      },
    },
  }
}

const state = {
  bot: null,
  loading: false,
  intents_class: null,
  loadingIntents: false,
  stats_loading: false,
  stats: [],
  list_loading: false,
  list: [],
  published: null,
  exportImportProcess: false,
  fade: false,
  actionStack: [],
  errors: {},
  next: {},
  blocks: () => blocks(),
  templates_loading: false,
  templates: [],
  servers: [],
  languages: [],
  translating: false,
  check_loading:{},
  check: {},
};

const getters = {
  action: state => {
    if (state.actionStack.length === 0) {
      return {
        recipient: '',
        type: '',
        data: null,
      };
    }
    return state.actionStack[0];
  },
  intents: state => {
    if (state.intents_class === null) {
      return [];
    }
    return state.intents_class.intents;
  },
  intent: state => id => {
    if (state.intents_class === null || id === 0) {
      return Bot.createBlock('user', 'custom');
    }
    if (state.intents_class.intents.hasOwnProperty(id)) {
      return state.intents_class.intents[id];
    }
    return Bot.createBlock('user', 'custom');
  },
};

const actions = {
  actionDone({ commit }, data) {
    commit('actionShifted', data);
  },
  actionPush({ commit }, data) {
    commit('actionPushed', data);
  },
  create({ commit }, title) {
    commit('loading', true);
    commit('created', title);
    commit('loading', false);
  },
  createIntents({ commit }) {
    commit('loadingIntents', true);
    commit('createdIntents');
    commit('loadingIntents', false);
  },
  destroy({ commit, state }, id) {
    return api.delete('robot.script.update', id, state, commit, 'destroyed');
  },
  export({ commit, state }, id) {
    return api.load('robot.script.export', id, state, commit, 'exporting', 'exported');
  },
  // Импортирует скрипт из файла file (*.tar.gz)
  import({ commit, state }, { file }) {
    const data = new FormData();
    data.append('script', file);
    return api.create('robot.script.import', 0, data, state, commit, 'exporting', 'copied', 'errors');
  },
  publish({ commit, state }, { id, ats_ip, iv }) {
    return api.list('robot.script.publish', id, { ats_ip, iv }, state, commit, 'loading', 'published');
  },
  copy({ commit, state }, data) {
    const id = data.id;
    return api.create('robot.script.copy', id, data, state, commit, 'loading', 'copied', 'errors');
  },
  fade({ commit }, value) {
    commit('fade', value);
  },
  load({ commit, state }, id) {
    return api.load('robot.script', id, state, commit, 'loading', 'loaded');
  },
  loadIntents({ commit, state }, id) {
    return api.load('robot.intents', id, state, commit, 'loadingIntents', 'loadedIntents');
  },
  save({ commit, state }, data) {
    if (data.id > 0) {
      return api.update('robot.script.update', data.id, data, state, commit, 'loading', 'loaded', 'errors');
    } else {
      return api.create('robot.script.create', 0, data, state, commit, 'loading', 'loaded', 'errors');
    }
  },
  check({ commit, state }, data) {
    return api.list('robot.script.check', data.id, data, state, commit, 'check_loading', 'check_loaded');
  },
  // Отправляет запрос на получение количества звонков роботами
  stats({ commit, state }, data) {
    return api.list('robot.stats', 0, data, state, commit, 'statsLoading', 'statsLoaded');
  },
  // Отправляет запрос на получение простого списка сценариев роботов
  list({ commit, state }, data) {
    return api.list('robot.script.list', 0, data, state, commit, 'listLoading', 'listLoaded');
  },
  // Отправляет запрос на получение списка одобренных шаблонов от 360dialog
  templates({ commit, state }) {
    return api.list('robot.chat.get_templates', 0, [], state, commit, 'templatesLoading', 'templatesLoaded');
  },
  // Отправляет запрос на тестовый диалог с роботом
  initDialog({ commit, state }, data) {
    return api.create('robot.chat.init', 0, data, state, commit, 'dialogInitializing', 'pass', 'errors');
  },
  tts({ commit, state }, model) {
    return http.post(`${api.main_url}/api/v1/cloud/speech/synthesize`, model, {
      responseType: 'blob',
    });
  },
  languages({ commit, state }) {
    commit('languagesLoaded', []);

    return new Promise((resolve) => {
      http.get(`${api.main_url}/api/v1/cloud/translate/languages`).then((response) => {
        commit('languagesLoaded', response.data);
        resolve(true);
      }).catch((err) => {
        resolve(err.message || err.exception || 'Unknown error');
      });
    });
  },
  translate({ commit, state }, request) {
    commit('translating', true);

    return new Promise((resolve, reject) => {
      http.post(`${api.main_url}/api/v1/cloud/translate`, request).then((response) => {
        resolve(response);
      }).catch((err) => {
        reject(err.message || err.exception || 'Unknown error');
      }).finally(() => {
        commit('translating', false);
      });
    });
  },
};

const mutations = {
  actionPushed(state, data) {
    state.actionStack.push(data);
  },
  actionShifted(state, data) {
    if (state.actionStack[0] === data) {
      state.actionStack.shift();
    }
  },
  created(state, title) {
    state.bot = Bot.createBot(title);
  },
  createdIntents(state, data = null) {
    state.intents_class = Bot.createIntent(data);
  },
  destroyed(state, id) {
    if (state.bot && state.bot.id && state.bot.id === id) {
      state.bot = null;
    }
  },
  errors(state, errors) {
    state.errors = errors;
  },
  exported(state, data) {
    if (state && data && _.isString(data)) {
      window.open(`${api.main_url}/${data}`);
    }
  },
  exporting(state, data) {
    state.exportImportProcess = data;
  },
  fade(state, value) {
    state.fade = value;
  },
  loaded(state, data) {
    if (_.isObject(data)) {
      state.bot = Bot.createBot(data);
      state.bot.checkIntents();
      state.servers = data.servers;
    } else {
      state.bot = null;
      state.servers = [];
    }
  },
  loadedIntents(state, data) {
    if (_.isObject(data) && _.size(data.data)) {
      state.intents_class = Bot.createIntent(data.data);
    } else {
      state.intents_class = Bot.createIntent();
    }
  },
  // При получении количества звонков роботами
  statsLoaded(state, data) {
    if (data && _.isArray(data.data)) {
      state.stats = data.data;
    }
  },
  // При получении списка сценариев
  listLoaded(state, data) {
    if (data && _.isArray(data.data)) {
      state.list = data.data;
    } else {
      state.list = [];
    }
  },
  loading(state, data) {
    state.loading = data;
  },
  loadingIntents(state, data) {
    state.loadingIntents = data;
  },
  // Переключает состояние загрузки количества звонков роботами
  statsLoading(state, data) {
    state.stats_loading = data;
  },
  // Переключает состояние загрузки списка сценариев
  listLoading(state, data) {
    state.list_loading = data;
  },
  copied(state, { id }) {
    if (state) {
      VueRouter.push({
        name: 'robot-editor',
        params: { id },
      });
    }
  },
  published(state, data) {
    if (data && data.data) {
      state.published = data.data;
    } else {
      state.published = null;
    }
  },
  templatesLoading(state, data) {
    state.templates_loading = data;
  },
  templatesLoaded(state, data) {
    if (data && _.isArray(data.data)) {
      state.templates = data.data;
    } else {
      state.templates = [];
    }
  },
  dialogInitializing(state, data) {
    state.chat_initializing = data;
  },
  pass() {},
  languagesLoaded(state, data) {
    state.languages = data;
  },
  translating(state, data) {
    state.translating = data;
  },
  check_loading(state, data) {
    state.check_loading = data;
  },
  check_loaded(state, data) {
    if (data && !_.isEmpty(data.data)) {
      state.check = data.data;
    } else {
      state.check = [];
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
