import api from '@/lib/api';

export const CUSTOM_INTEGRATIONS_EVENTS = 'CUSTOM_INTEGRATIONS_EVENTS';
export const CUSTOM_INTEGRATIONS_EVENT_FIELDS = 'CUSTOM_INTEGRATIONS_EVENT_FIELDS';
export const CUSTOM_INTEGRATIONS_RUN = 'CUSTOM_INTEGRATIONS_RUN';
export const CUSTOM_INTEGRATIONS_RUN_CHANGE = 'CUSTOM_INTEGRATIONS_RUN_CHANGE';
const CUSTOM_INTEGRATIONS_RUNNING = 'CUSTOM_INTEGRATIONS_RUNNING';
const CUSTOM_INTEGRATIONS_RUN_ERRORS_CHANGE = 'CUSTOM_INTEGRATIONS_RUN_ERRORS_CHANGE';

export const HH_SET_SETTINGS = 'HH_SET_SETTINGS';
export const HH_DELETE = 'HH_DELETE';
export const HH_LOAD_RESUMES = 'HH_LOAD_RESUMES';

const EVENTS_LOADING_CHANGE = 'EVENTS_LOADING_CHANGE';
const EVENTS_CHANGE = 'EVENTS_CHANGE';
const FIELDS_LOADING_CHANGE = 'FIELDS_LOADING_CHANGE';
const FIELDS_CHANGE = 'FIELDS_CHANGE';

const HH_SAVING_CHANGE = 'HH_SAVING_CHANGE';
const HH_CHANGE = 'HH_CHANGE';
const HH_ERRORS_CHANGE = 'HH_ERRORS_CHANGE';
const HH_DATA_DELETE = 'HH_DATA_DELETE';
const HH_LOAD_RESUMES_LOADING_CHANGE = 'HH_LOAD_RESUMES_LOADING_CHANGE';
const HH_LOAD_RESUMES_CHANGE = 'HH_LOAD_RESUMES_CHANGE';
const HH_LOAD_RESUMES_ERRORS_CHANGE = 'HH_LOAD_RESUMES_ERRORS_CHANGE';
export const BOT_CAMPAIGN_CREATE_CONTRAHEN_BY_HH    = 'BOT_CAMPAIGN_CREATE_CONTRAHEN_BY_HH';
const HH_BOT_CAMPAIGN_SAVING_CHANGE = 'HH_BOT_CAMPAIGN_SAVING_CHANGE';
const HH_BOT_CAMPAIGN_CHANGE = 'HH_BOT_CAMPAIGN_CHANGE';
const HH_BOT_CAMPAIGN_ERRORS_CHANGE = 'HH_BOT_CAMPAIGN_ERRORS_CHANGE';

const state = {
  custom: {
    events: [],
    loading: false,
    running: false,
    errors: {},
    result: {},
  },
  event: {
    fields: {},
    loading: false,
  },
  hh_saving: false,
  hh: null,
  hh_errors:{},
  hh_resumes_loading : false,
  hh_resumes         : {},
  hh_resumes_errors  : {},
  bot_campaign_contrahen_saving     : false,
  bot_campaign_contrahen            : null,
  bot_campaign_contrahen_errors     : {},
};

const getters = {
  eventNames: (state) => Object.fromEntries(state.custom.events.map(({ id, title }) => [id, title])),
};

const actions = {
  [CUSTOM_INTEGRATIONS_EVENTS]({ commit, state }) {
    api.load(
      'custom_integrations.event_list',
      0,
      state,
      commit,
      EVENTS_LOADING_CHANGE,
      EVENTS_CHANGE
    );
  },
  [CUSTOM_INTEGRATIONS_EVENT_FIELDS]({ commit, state }, id) {
    api.load(
      'custom_integrations.event_field_list',
      id,
      state,
      commit,
      FIELDS_LOADING_CHANGE,
      FIELDS_CHANGE
    );
  },
  [CUSTOM_INTEGRATIONS_RUN]({ commit, state }, { id, params }) {
    return api.create(
      'custom_integrations.run',
      id,
      params,
      state,
      commit,
      CUSTOM_INTEGRATIONS_RUNNING,
      CUSTOM_INTEGRATIONS_RUN_CHANGE,
      CUSTOM_INTEGRATIONS_RUN_ERRORS_CHANGE,
    );
  },

  [HH_SET_SETTINGS]({ commit, state }, data){
    return api.create(
      'hh.set_settings',
      0,
      data,
      state,
      commit,
      HH_SAVING_CHANGE,
      HH_CHANGE,
      HH_ERRORS_CHANGE,
    );
  },
  [HH_DELETE]({ commit, state }, id) {
    return api.delete(
      "hh.data.delete",
      id,
      state,
      commit,
      HH_DATA_DELETE
    );
  },
  [HH_LOAD_RESUMES] ({ commit, state }, filter) {
    return api.list(
      'hh.resumes',
      0,
      filter,
      state,
      commit,
      HH_LOAD_RESUMES_LOADING_CHANGE,
      HH_LOAD_RESUMES_CHANGE,
      HH_LOAD_RESUMES_ERRORS_CHANGE
    );
  },
  // Добавление контактов в кампании через фильтр
  [BOT_CAMPAIGN_CREATE_CONTRAHEN_BY_HH]({ commit, state }, data) {
      return api.create(
        'hh.create_contrahen',
        0,
        data,
        state,
        commit,
        HH_BOT_CAMPAIGN_SAVING_CHANGE,
        HH_BOT_CAMPAIGN_CHANGE,
        HH_BOT_CAMPAIGN_ERRORS_CHANGE
      );
  },
};

const mutations = {
  [EVENTS_LOADING_CHANGE](state, data) {
    state.custom.loading = data;
  },
  [EVENTS_CHANGE](state, data) {
    if (data) {
      state.custom.events = data;
    } else {
      state.custom.events = [];
    }
  },
  [CUSTOM_INTEGRATIONS_RUN_CHANGE](state, data) {
    state.custom.result = data;
  },
  [CUSTOM_INTEGRATIONS_RUNNING](state, data) {
    state.custom.running = data;
  },
  [CUSTOM_INTEGRATIONS_RUN_ERRORS_CHANGE](state, data) {
    state.custom.errors = data;
  },
  [FIELDS_LOADING_CHANGE](state, data) {
    state.event.loading = data;
  },
  [FIELDS_CHANGE](state, data) {
    if (data) {
      state.event.fields = data;
    } else {
      state.event.fields = {};
    }
  },

  [HH_SAVING_CHANGE](state, data) {
    state.hh_saving = data;
  },
  [HH_CHANGE](state, data) {
    state.hh = data;
  },
  [HH_ERRORS_CHANGE](state, data) {
    state.hh_errors = data;
  },
  [HH_DATA_DELETE] (state, data) {
    if(data) {
       state.hh = null;
    }
  },
  [HH_LOAD_RESUMES_LOADING_CHANGE](state, data) {
    state.hh_resumes_loading = data;
  },
  [HH_LOAD_RESUMES_CHANGE](state, data) {
    if (data) {
      state.hh_resumes          = data.data;
      state.hh_resumes_total    = data.total;
    } else {
      state.hh_resumes          = {};
      state.hh_resumes_total    = 0;
    }
  },
  [HH_LOAD_RESUMES_ERRORS_CHANGE](state, data) {
    state.hh_resumes_errors   = data;
  },

  [HH_BOT_CAMPAIGN_SAVING_CHANGE](state, data) {
    state.bot_campaign_contrahen_saving   = data;
  },
  [HH_BOT_CAMPAIGN_CHANGE](state, data) {
    state.bot_campaign_contrahen = data;
  },
  [HH_BOT_CAMPAIGN_ERRORS_CHANGE](state, data) {
    state.bot_campaign_contrahen_errors   = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
